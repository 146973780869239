import React, {Component} from 'react';
import {
    Link
} from 'react-router-dom'

import '../css/header.css';
import '../css/home.css';
import '../css/footer.css';

import footerImg from '../img/powered-by.png';
import logo from '../img/logo.png';
import Loader from "react-loader-spinner";

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            all_contents: [],
            isLoading: false
        }
        this.sayHello = this.sayHello.bind(this);
    }

    sayHello(e) {
        //alert(e);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(process.env.REACT_APP_API_URL+"/api/web/v1/get_speed_dials", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    all_contents: result.data,
                    isLoading: true
                })
            })
            .catch(error => console.log('error', error));

    }

    render() {
        return (
            <div>
                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}


              
                        {/* // ======================speed_dials(11)================== */}

                            
                                <div className="mbContainer">
                                    <div className="container text-center">
                                        <div className="row cusRow">

                                            {

                                                !this.state.isLoading ?
                                                    <div className="loader">
                                                        <Loader
                                                            type="Circles"
                                                            color="#dc1c2c"
                                                            height={50}
                                                            width={100}
                                                            // timeout={1000} //3 secs
                                                        />
                                                    </div>

                                                :

                                                this.state.all_contents.map(cat =>
                                                <div className="text-center singleItem col">
                                                    <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))}>
                                                        <div className="itemImg">
                                                            <img src={cat.banner_image} className="img-fluid"
                                                                 alt="img"/>
                                                        </div>
                                                        <div className="title">
                                                            {cat.title_bn}
                                                        </div>

                                                    </a>
                                                </div>
                                            )


                                            }

                                        </div>
                                    </div>
                                </div>
                            
                        {/* // ======================end speed_dials(11)=============== */}

            </div>
        );
    }
}

export default HomePage;